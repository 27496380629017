import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";

export default function Amjad() {
  const [data, setData] = useState([]);
  const [maids] = useState("");
  const [isLoading, setIsLoading] = useState(true); // State to track loading
  const [error, setError] = useState(null); // State to track errors

  useEffect(() => {
    fetch(`${erpApiUrl}&status[]=approved`)
      .then((response) => response.json())
      .then((result) => setData(result.response))
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const filteredData = data.filter((a) => a.maid_type === "" && a.name.toLowerCase().includes(maids.toLowerCase()));

  if (error) {
    return <div className="error-message">Error loading data: {error.message}</div>; // Display error message
  }

  if (isLoading) {
    return <div className="loading-message">Loading...</div>; // Display loading message
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <Allmaids data={filteredData} />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import "../Allmaids.css";
import { erpApiUrl } from "../global/erpAPI";
import Allmaids from "../AllMaids";

export default function OutsideCounty() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New state to track loading
  const [error, setError] = useState(null); // New state to track errors

  useEffect(() => {
    fetch(`${erpApiUrl}&status[]=pending`)
      .then((response) => response.json())
      .then((result) => setData(result.response))
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => setIsLoading(false)); // Set loading to false when fetch is complete
  }, []);

  const filteredData = data.reduce((accumulator, item) => {
    if (item.country_status === "Out Side Country") {
      accumulator.push(item);
    }
    return accumulator;
  }, []);

  if (error) {
    return <div className="error-message">Error loading data: {error.message}</div>; // Display error message
  }

  if (isLoading) {
    return <div className="loading-message">Loading...</div>; // Display loading message
  }

  return (
    <div style={{ marginTop: '20px'}}>
      <Allmaids data={filteredData} /> 
    </div>
  );
}

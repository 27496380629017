import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Amjad from './hooks/categories/Amjad';
import OutsideCounty from './hooks/categories/Outsidecounty';
import Package4Maids from './hooks/categories/Package4Maids';
import NavBar from './navbar/NavBar';
import LogoHomecare from './navbar/Logo';


function App() {
  return (
    <Router>

      <Helmet>
        <title>Tadbeer Homecare </title>
        <meta name="description" content="Homecare is your one stop destination to find a solution to all your domestic workers visa services requirements ,UAE’s TADBEER center for hiring full-time maids, nannies, and issuing visas. Sign &amp; Pay online in 5 minutes. Avoid visits to medical centers &amp; typists." />
        <meta name="keywords" content="Maids, maids in Dubai, dubai maids, maids full time, live in maids, maid, available maids, nannies, full time nannies, dubai nannies, tadbeer, tadbeer dubai, tadbeer maids, tadbeer in dubai, visa, tadbeer uae, tadbeer jumirah, al wasl road tadbeer, tadbeer for maids, cleaning services, Full time nanny, dubai full time maid, housekeeper, cooks, babysitter dubai, tadbeer full time maid, under tadbeer visa, Tadbeer center, tadbeer centers, tadbeer centr , Most trusted tadbeer in uae , Most trusted tadbeer in dubai , Biggest tadbeer in uae , Biggest tadbeer in dubai, تدبير ،تدبير دبي، مركز تدبير ، مركز تدبير في دبي ، تدبير الجميرا ، تدبير للعاملات ، كفالة تدبير ، ناني ، ناني في دبي ، دبي ناني" />
      </Helmet>
       
       <NavBar/>
      
      
       <LogoHomecare/>

        <div>
          <Routes>
            <Route path="/" element={<Amjad />} />
            <Route path="/outside" element={<OutsideCounty />} />
            <Route path="/package4" element={<Package4Maids />} />
          </Routes>
        </div>
      
    </Router>
  );
}

export default App;
